import {PublicApi} from "./PublicApi";

require('./styles/index.less');

if (window.__qchat__initialized) {
    console.error('Q-Chat уже загружен. Возможно код виджета добавлен на страницу несколько раз');
} else {
    window.__qchat__initialized = true;

    let apiKey;
    let authId;
    let authKey;
    let list = document.getElementsByTagName('script');

    for (let i = 0; i < list.length; i++) {
        apiKey = list[i].getAttribute('data-qc-api-key');
        authId = list[i].getAttribute('data-qc-auth-id');
        authKey = list[i].getAttribute('data-qc-auth-key');

        if (apiKey) {
            break;
        }
    }

    if (apiKey) {
        if (authId && authKey) {
            window.qchatapi = new PublicApi(apiKey, authId, authKey);
        } else {
            window.qchatapi = new PublicApi(apiKey);
        }
    } else {
        console.error('Скрипт Q-Chat установлен неверно. Не удается обнаружить API Key');
    }
}