export interface BusMessage {
    bus_name: string;
    action: string;
    data?: any;
}

export class BrowserEventBus {
    protected listeners = {};

    constructor(protected winFrom: Window, protected winTo: Window, protected busName: string) {
        winFrom.addEventListener('message', (message) => {
            let busMessage: BusMessage;

            if (message.data) {
                try {
                    busMessage = JSON.parse(message.data); // Принимаем только json сообщения
                } catch (e) {
                    return;
                }

                if (busMessage && busMessage.bus_name === this.busName && this.listeners[busMessage.action]) {
                    for (let cb of this.listeners[busMessage.action]) {
                        cb(busMessage.data);
                    }
                }
            }
        });
    }

    setWinTo(winTo: Window): this {
        this.winTo = winTo;

        return this;
    }

    /**
     * Подписка на новые собщения
     */
    on(action: string, cb: (message: any) => void): this {
        if (!this.listeners[action]) {
            this.listeners[action] = [];
        }

        this.listeners[action].push(cb);

        return this;
    }

    /**
     * Отправка сообщения
     */
    send(action: string, data?: any): void {
        try {
            this.winTo.postMessage(JSON.stringify({bus_name: this.busName, action: action, data: data}), '*');
        } catch (e) {
            console.error(e);
        }
    }
}