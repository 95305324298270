import {Model} from "derby";

export class EventModel {
    static readonly NAME_CHAT = '$chat'; // События с окном чата
    static readonly NAME_LOCATION = '$location'; // События перемещения по сайту

    /**
     * Создание события
     */
    static create(model: Model, clientId: string, name: string, data = {}, next?: any): string {
        return model.add('event', {
            created: Date.now(),
            client_id: clientId,
            name: name,
            data: data
        }, next);
    }
}