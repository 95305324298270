export interface ChatIconMessage {
    user?: {
        avatar: string;
        name: string;
    };

    content: string;
    attaches?: string[];
}

/**
 * Иконка виджета чата
 */
export class ChatIcon {
    protected domIconPopup: HTMLDivElement;
    protected domIcon: HTMLDivElement;
    protected domMsgContainer: HTMLDivElement;

    protected eventListeners = {};

    constructor() {
        const chatIcon = document.createElement('div');
        chatIcon.id = 'qchat_button';
        chatIcon.className = 'hidden';
        chatIcon.onclick = () => {
            if (this.eventListeners['click']) {
                for (let cb of this.eventListeners['click']) {
                    cb();
                }
            }
        };
        chatIcon.innerHTML = `<div class="qchat--chat-button">
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 47.926 47.926"><path d="M39.928.09H8c-4.4 0-8 3.6-8 8v20.977c0 4.4 3.6 8 8 8h7.397v10.77l13.333-10.77h11.195c4.399 0 8-3.6 8-8V8.09c.003-4.4-3.597-8-7.997-8zm3 28.976c0 1.627-1.374 3-3 3H26.962l-1.375 1.11-5.191 4.193v-5.305H8c-1.626 0-3-1.373-3-3V8.09c0-1.626 1.374-3 3-3h31.928c1.626 0 3 1.374 3 3v20.976z"/><circle cx="13.707" cy="18.58" r="2.913"/><circle cx="23.964" cy="18.58" r="2.913"/><circle cx="34.22" cy="18.58" r="2.913"/></svg>
</div>`;

        const msgContainer = document.createElement('div');
        msgContainer.className = 'qchat--msg-list';
        chatIcon.appendChild(msgContainer);

        // Обязательно после контейнера с сообщениями
        const popupMessage = document.createElement('div');
        popupMessage.className = 'qchat--chat-popup';
        popupMessage.innerHTML = `<span></span>`;
        chatIcon.appendChild(popupMessage);

        document.body.appendChild(chatIcon);

        this.domIcon = chatIcon;
        this.domIconPopup = popupMessage;
        this.domMsgContainer = msgContainer;
    }

    /**
     * Показываем иконку чата
     */
    show() {
        this.domIcon.classList.remove('hidden');
    }

    /**
     * Скрываем иконку чата
     */
    hide() {
        this.domIcon.classList.add('hidden');
    }

    /**
     * Добавление обработчика событий
     */
    on(event: string, cb: () => void) {
        if (!this.eventListeners[event]) {
            this.eventListeners[event] = [];
        }

        this.eventListeners[event].push(cb);
    }

    /**
     * Очистка сообщений чата
     */
    clearMessages(): void {
        this.domMsgContainer.innerHTML = '';
    }

    /**
     * Добавление сообщения чата
     */
    addMessage(message: ChatIconMessage): void {
        const domMsg = document.createElement('div');
        domMsg.className = 'qchat--msg-item qchat--msg-item-hidden';
        domMsg.innerHTML = `<div class="qchat--msg-item--avatar">
    <img src="${message.user.avatar}" alt="${message.user.name}"/>
    <div class="qchat--msg-item--name" title="${message.user.name}">${message.user.name}</div>
</div>`;

        const domMsgtext = document.createElement('div');
        domMsgtext.className = 'qchat--msg-item--text';
        if (message.content) {
            domMsgtext.innerText = message.content;
        } else if (message.attaches) {
            domMsgtext.innerText = '[вложение файла]';
        } else {
            domMsgtext.innerText = '[новое сообщение]';
        }

        domMsg.appendChild(domMsgtext);

        this.domMsgContainer.appendChild(domMsg);

        setTimeout(() => {
            domMsg.classList.remove('qchat--msg-item-hidden');
        }, 13);
    }

    /**
     * Установка всплывающего сообщения
     */
    setPopupMessage(message?: string): void {
        if (message) {
            this.domIconPopup.innerHTML = `<span>${message}</span>`;
        } else {
            this.domIconPopup.innerHTML = ``;
        }
    }
}