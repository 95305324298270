import Cookies from "js-cookie";
import Favico from "favico.js";
import {EventModel} from "../shared/models/EventModel";
import {BrowserEventBus} from "../shared/BrowserEventBus";
import {ChatIcon} from "./ChatIcon";

interface Project {
    title: string;
    website: string;
    options: {
        avatar: string;
        chat_color: string;
        hello_message?: string;
        popup_message?: string;
    };
}

interface ClientProps {
    [key: string]: string | number | boolean;
}

interface ClientMetaBrowser {
    location: {
        title: string;
        href: string;
    }
}

interface AuthResult {
    clientId: string;
    project: Project;
}

export class PublicApi {
    static readonly COOKIE_CLIENT_ID = 'qchat_client_id';

    protected apiKey: string;
    protected authId: string;
    protected authKey: string;
    protected unreadCount: number = 0;

    protected clientId: string;
    protected project: Project;

    protected domFrame: HTMLIFrameElement;
    protected domFrameContainer: HTMLDivElement;

    protected __interval__lm: NodeJS.Timeout;
    protected __userInteracted: boolean;

    protected __doc_url: string;
    protected __doc_title: string;

    protected _initPromise: Promise<void>;
    protected frameEventBus = new BrowserEventBus(window, window, 'q-chat');

    protected chatIcon: ChatIcon;

    constructor(apiKey: string, authId?: string, authKey?: string) {
        if (!apiKey) {
            throw new Error('Api key not provided');
        }

        this.apiKey = apiKey;
        this.authId = authId;
        this.authKey = authKey;

        this.chatIcon = new ChatIcon();
        this.chatIcon.on('click', () => this.open());

        this._initPromise = new Promise(async (resolve) => {
            await this._init();

            this._initStyles();
            this._initEvents();
            this._enableLocationMonitor();
            this._initFrame(() => {
                this.frameEventBus.send('widget-init');
                resolve();

                setTimeout(() => {
                    document.body.addEventListener('mousemove', this._onUserInteract.bind(this));
                    document.body.addEventListener('mousedown', this._onUserInteract.bind(this));
                    document.body.addEventListener('keydown', this._onUserInteract.bind(this));
                }, 1000);
            });

            this.chatIcon.setPopupMessage(this.project.options.popup_message);
            this.chatIcon.show();
        });
    }

    /**
     * Каллбек по готовности чата
     */
    onReady(cb: () => void): void {
        this._initPromise.then(cb);
    }

    /**
     * Открываем чат
     */
    open(): void {
        this._showFrame();
        this.chatIcon.hide();
        this.chatIcon.clearMessages();
    }

    /**
     * Закрываем чат
     */
    close(): void {
        this._hideFrame();
        this.chatIcon.clearMessages();
        this.chatIcon.show();
    }

    /**
     * Ручная авторизация
     */
    async auth(authId: string, authKey: string): Promise<boolean> {
        const cookieClientId = Cookies.get(PublicApi.COOKIE_CLIENT_ID);
        const authResult = await this._authClient(authId, authKey, cookieClientId);

        this.authId = authId;
        this.authKey = authKey;
        this.clientId = authResult.clientId;
        this.project = authResult.project;

        this._updateFrameUrl();

        return true;
    }

    protected async _apiCall(method: string, data = {}) {
        await this._initPromise;

        let url = process.env.Q_URL + '/api/client/' + method + '?client_id=' + encodeURIComponent(this.clientId);
        let response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        }).then(r => r.json());

        if (!response.success) {
            console.error('Server response', response);
            throw new Error('Неудачный ответ сервера');
        }

        return response;
    }

    /**
     * Отправка сообщения в чат от имени пользователя
     */
    async sendMessage(message: string) {
        return this._apiCall('sendmessage', {message});
    }

    /**
     * Установка свойств клиента
     */
    async setProps(props: ClientProps) {
        return this._apiCall('setprops', {props: props});
    }

    /**
     * Установка мета-свойств браузера
     */
    protected async _setMetaBrowser(data: ClientMetaBrowser) {
        return this._apiCall('setmetabrowser', {meta: data});
    }

    /**
     * Создание события
     */
    async createEvent(name: string, data = {}) {
        return this._apiCall('addevent', {name, data});
    }

    /**
     * Инициализация
     */
    protected async _init(): Promise<void> {
        let cookieClientId = Cookies.get(PublicApi.COOKIE_CLIENT_ID);

        let initList = [];

        if (this.authId && this.authKey) {
            initList.push(async () => { // Пробуем нормальную авторизацию
                return await this._authClient(this.authId, this.authKey, cookieClientId);
            });
        }

        if (cookieClientId) {
            initList.push(async () => { // Пробуем авторизацию через куку
                const authResult = await this._validateClientId(cookieClientId);

                Cookies.set(PublicApi.COOKIE_CLIENT_ID, authResult.clientId, {expires: 90, path: '/'});

                return authResult;
            });
        }

        initList.push(async () => { // Генерация нового клиента
            const authResult = await this._getNewClientId();

            Cookies.set(PublicApi.COOKIE_CLIENT_ID, authResult.clientId, {expires: 90, path: '/'});

            return authResult;
        });

        while (initList.length > 0) {
            const fn = initList.shift();
            try {
                const authResult = await fn();

                this.clientId = authResult.clientId;
                this.project = authResult.project;

                return;
            } catch (err) {
                console.error(err);
            }
        }

        throw new Error('Не удается инциализировать авторизацию клиента');
    }

    protected _initStyles() {
        if (this.project) {
            let style = document.createElement('style');
            style.id = 'qchat_styles';
            style.innerText = ':root { --qchat-color: ' + this.project.options.chat_color + ' !important; }';
            document.body.appendChild(style);
        }
    }

    protected _initEvents() {
        let favicon = new Favico({
            animation: 'none',
            textColor: '#fff',
            bgColor: '#0078ff',
        });

        this.frameEventBus.on('messages-new', messages => { // Получение нового сообщения
            if (messages && messages.length > 0) {
                for (let message of messages) {
                    this.chatIcon.addMessage(message);
                }
            }
        });

        this.frameEventBus.on('messages-unread-count', (count) => { // Обновляем счетчик непрочитанных сообщений
            this.unreadCount = parseInt(count || '0', 10);
            favicon.badge(this.unreadCount);
        });
    }

    /**
     * Генерация нового ClientId
     */
    protected async _getNewClientId(): Promise<AuthResult> {
        let response = await fetch(process.env.Q_URL + '/api/client/new?project_api_key=' + encodeURIComponent(this.apiKey), {
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then(response => response.json());

        return this._checkAuthResultResponse(response);
    }

    /**
     * Валидация ClientId
     */
    protected async _validateClientId(clientId: string): Promise<AuthResult> {
        let response = await fetch(process.env.Q_URL + '/api/client/identify?project_api_key=' + encodeURIComponent(this.apiKey) + '&client_id=' + encodeURIComponent(clientId), {
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then(response => response.json());

        return this._checkAuthResultResponse(response);
    }

    /**
     * Авторизация
     */
    protected async _authClient(authId: string, authKey: string, clientId?: string): Promise<AuthResult> {
        let url = process.env.Q_URL + '/api/client/auth?project_api_key=' + encodeURIComponent(this.apiKey);

        if (clientId) {
            url += '&client_id=' + encodeURIComponent(clientId);
        }

        url += '&auth_id=' + encodeURIComponent(authId);
        url += '&auth_key=' + encodeURIComponent(authKey);

        let response = await fetch(url, {
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then(response => response.json());

        return this._checkAuthResultResponse(response);
    }

    protected _checkAuthResultResponse(response: any): AuthResult {
        if (!response.success) {
            throw new Error('Неудачный ответ сервера');
        }

        if (!response.client_id) {
            throw new Error('Не получен client_id');
        }

        if (!response.project) {
            throw new Error('Не получен project');
        }

        return {
            clientId: response.client_id,
            project: response.project,
        };
    }

    /**
     * Обновление URL фрейма
     */
    protected _updateFrameUrl() {
        if (this.domFrame) {
            let newUrl = this._getChatFrameUrl();
            if (newUrl !== this.domFrame.src) {
                this.domFrame.src = this._getChatFrameUrl();
            }
        }
    }

    /**
     * Инициализация фрейма
     */
    protected _initFrame(onload?: () => void): void {
        let closeTimes = document.createElement('div');
        closeTimes.title = 'Закрыть чат';
        closeTimes.className = 'close-times';
        closeTimes.innerHTML = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 212.982 212.982"><path d="M131.804 106.491l75.936-75.936c6.99-6.99 6.99-18.323 0-25.312-6.99-6.99-18.322-6.99-25.312 0L106.491 81.18 30.554 5.242c-6.99-6.99-18.322-6.99-25.312 0-6.989 6.99-6.989 18.323 0 25.312l75.937 75.936-75.937 75.937c-6.989 6.99-6.989 18.323 0 25.312 6.99 6.99 18.322 6.99 25.312 0l75.937-75.937 75.937 75.937c6.989 6.99 18.322 6.99 25.312 0 6.99-6.99 6.99-18.322 0-25.312l-75.936-75.936z" fill-rule="evenodd" clip-rule="evenodd"/></svg>`;
        closeTimes.onclick = this.close.bind(this);

        let frameContainer = document.createElement('div');
        frameContainer.id = 'qchat_chat_frame';
        frameContainer.className = 'hidden';

        let frame = document.createElement('iframe');
        frame.onload = function () {
            onload && onload();
        };

        frameContainer.appendChild(frame);
        frameContainer.appendChild(closeTimes);
        document.body.appendChild(frameContainer);

        frame.src = this._getChatFrameUrl();

        this.domFrameContainer = frameContainer;
        this.domFrame = frame;
        this.frameEventBus.setWinTo(this.domFrame.contentWindow);
    }

    /**
     * Показываем фрейм
     */
    protected _showFrame() {
        if (this._isFrameShowed()) {
            return;
        }

        if (this.domFrameContainer) {
            this.domFrameContainer.classList.remove('hidden');
            this.createEvent(EventModel.NAME_CHAT, {action: 'open'});
            this.frameEventBus.send('frame-open');
        }
    }

    /**
     * Скрываем фрейм
     */
    protected _hideFrame() {
        if (!this._isFrameShowed()) {
            return;
        }

        if (this.domFrameContainer) {
            this.domFrameContainer.classList.add('hidden');
            this.createEvent(EventModel.NAME_CHAT, {action: 'close'});
        }
    }

    /**
     * Генерация URL для фрейма
     */
    protected _getChatFrameUrl() {
        return `${process.env.Q_URL}/chat/${this.apiKey}?client_id=` + encodeURIComponent(this.clientId);
    }

    /**
     * Фрейм показывается?
     */
    protected _isFrameShowed() {
        return !this.domFrameContainer.classList.contains('hidden');
    }

    /**
     * Мониторин текущей страницы - ВКЛ
     */
    protected _enableLocationMonitor() {
        this._disableLocationMonitor();
        this._updateLocation();
        this.__interval__lm = setInterval(this._updateLocation.bind(this), 3000);
    }

    /**
     * Мониторин текущей страницы - ВЫКЛ
     */
    protected _disableLocationMonitor() {
        !!this.__interval__lm && clearInterval(this.__interval__lm);
    }

    /**
     * Обновляем текущую страницу пользователя
     */
    protected _updateLocation() {
        if (this.__doc_url !== ('' + window.location.href)) {
            this.__doc_title = ('' + window.document.title);
            this.__doc_url = ('' + window.location.href);

            this._setMetaBrowser({location: {title: this.__doc_title, href: this.__doc_url}});
            this.createEvent(EventModel.NAME_LOCATION, {title: this.__doc_title, href: this.__doc_url});

            let props = {};
            let found = false;

            // UTM-метки
            let query = window.location.search.substring(1);
            let vars = query.split('&');
            for (let i = 0; i < vars.length; i++) {
                let pair = vars[i].split('=');
                let key = decodeURIComponent(pair[0]);
                let value = decodeURIComponent(pair[1]);

                if (key.match(/^utm_/)) {
                    props[key] = value;
                    found = true;
                }
            }

            if (found) { // Устанавливаем UTM-метки
                this.setProps(props);
            }
        }
    }

    /**
     * Отправляем информацию об активности клиента
     */
    protected _onUserInteract() {
        if (!this.__userInteracted) {
            this.__userInteracted = true;
            setTimeout(() => {
                this.__userInteracted = false;
            }, 10000);

            this.frameEventBus.send('interact');
        }
    }
}